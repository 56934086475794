import { FormInstance } from 'antd/lib/form/Form';
import { FieldData, FieldError } from 'rc-field-form/lib/interface';
import { Rule } from 'antd/lib/form';

import { FieldErrors } from 'modules/api-requests/entities';

export const NAME_VALIDATION_PATTERN = /^[\p{L}' ][, \p{L}'-]*$/u;

const fieldHasErrorFilter = (field: FieldData | FieldError): boolean => (field.errors || []).length > 0;

export const hasErrors = (fields: FieldData[] | FieldError[]): boolean => fields.some(fieldHasErrorFilter);

export const clearErrors = (wrappedForm: FormInstance, errFields: FieldErrors, clearErrorAction?: () => void) => {
  for (const field in errFields) {
    if (errFields.hasOwnProperty(field)) {
      wrappedForm.setFields([
        {
          name: field,
          value: wrappedForm.getFieldValue(field),
          errors: undefined,
        },
      ]);
    }
  }

  if (clearErrorAction) {
    clearErrorAction();
  }
};

export const setErrors = (wrappedForm: FormInstance, errFields: FieldErrors) => {
  for (const field in errFields) {
    if (errFields.hasOwnProperty(field)) {
      wrappedForm.setFields([
        {
          name: field,
          value: wrappedForm.getFieldValue(field),
          errors: [errFields[field]],
        },
      ]);
    }
  }
};

export const withoutSpace = (rule: Rule, value?: string) => {
  if (value && value.trim().includes(' ')) {
    return Promise.reject('Must not contain spaces');
  }
  return Promise.resolve();
};

export const clearErrorsOnFieldChange =
  <T>(form: FormInstance) =>
  (changedValues: Partial<T>) => {
    const cleanFields: FieldData[] = Object.entries(changedValues).map(([name]) => ({
      name,
      errors: [],
    }));
    form.setFields(cleanFields);
    return changedValues;
  };
