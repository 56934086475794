import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { useTranslation } from 'react-i18next';

import { SelectOption } from 'utils/getOptionsFromIdName';
import { r } from 'utils/r';
import { testIdNamespace } from '../utils';

type Props = SelectProps<string> & {
  options: SelectOption[];
};

const { Option } = Select;

export const MessengersSelect = ({ options, ...rest }: Props) => {
  const { t } = useTranslation('registration');

  return (
    <Select
      placeholder={r(t('registerForm.messengerTypeIdField.placeholder'))}
      showSearch
      filterOption
      optionFilterProp="children"
      data-testid={`${testIdNamespace}:messengerType`}
      {...rest}
    >
      {options.map(({ value, label }) => (
        <Option value={value} key={value} data-testid={`${testIdNamespace}:messengerType:${value}`}>
          {label}
        </Option>
      ))}
    </Select>
  );
};
