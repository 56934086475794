import { Form, Input } from 'antd';
import { Rule } from 'antd/lib/form';
import { InputProps } from 'antd/lib/input';
import { FormItemProps } from 'antd/lib/form/FormItem';

import i18n from 'modules/localization';
import { PASSWORD_VALIDATION_PATTERN, MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from 'components/const';
import { withoutSpace } from 'utils/form';

type Props = FormItemProps & {
  label?: string;
  fieldName?: string;
  autoComplete?: string;
  inputProps?: InputProps;
  testId?: string;
};

const passwordValidationRules: Rule[] = [
  {
    required: true,
    message: i18n.t('common:password.required'),
    validateTrigger: 'onBlur',
  },
  {
    pattern: PASSWORD_VALIDATION_PATTERN,
    message: i18n.t('common:password.pattern'),
    validateTrigger: 'onBlur',
  },
  {
    min: MIN_PASSWORD_LENGTH,
    message: i18n.t('common:password.minLength', { length: MIN_PASSWORD_LENGTH }),
    validateTrigger: 'onBlur',
  },
  { max: MAX_PASSWORD_LENGTH, validateTrigger: 'onBlur' },
  { validator: withoutSpace, message: i18n.t('common:password.noSpaceValidator') },
];

export const PasswordFormItem = ({
  label = i18n.t('common:password.label'),
  fieldName = 'password',
  autoComplete,
  inputProps,
  testId,
  ...props
}: Props) => {
  return (
    <Form.Item
      validateFirst
      label={label}
      name={fieldName}
      validateTrigger={['onBlur', 'onChange']}
      rules={passwordValidationRules}
      {...props}
    >
      <Input.Password type="password" autoComplete={autoComplete} data-testid={testId} {...inputProps} />
    </Form.Item>
  );
};

export const PasswordConfirmFormItem = ({
  label = i18n.t('common:confirmPassword.label'),
  autoComplete = 'new-password',
  inputProps,
  testId,
  ...props
}: Props) => (
  <Form.Item
    validateFirst
    label={label}
    name="confirmPassword"
    validateTrigger={['onBlur', 'onChange']}
    dependencies={['password']}
    rules={[
      ...passwordValidationRules,
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (!value || getFieldValue('password') === value) {
            return Promise.resolve();
          }
          return Promise.reject(i18n.t('common:confirmPassword.confirm'));
        },
        validateTrigger: 'onBlur',
      }),
    ]}
    {...props}
  >
    <Input.Password type="password" autoComplete={autoComplete} data-testid={testId} {...inputProps} />
  </Form.Item>
);
