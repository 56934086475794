import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import { FormItemProps } from 'antd/lib/form/FormItem';
import i18n from 'modules/localization';
import { r } from 'utils/r';

import { emailValidator } from './validators';
import { testIdNamespace } from '../utils';

type Props = FormItemProps & {
  label?: string;
  fieldName?: string;
  autoComplete?: string;
};

export const EmailFormItem = ({
  label = i18n.t('registration:registerForm.email.label'),
  fieldName = 'email',
  autoComplete = 'email',
  ...props
}: Props) => {
  const { t } = useTranslation('registration');
  return (
    <Form.Item
      label={label}
      name={fieldName}
      validateFirst
      validateTrigger={['onBlur', 'onChange']}
      rules={[
        {
          validator: emailValidator,
          message: t('registerForm.email.pattern'),
          validateTrigger: 'onBlur',
        },
        {
          required: true,
          message: t('registerForm.email.required'),
          validateTrigger: 'onBlur',
        },
      ]}
      {...props}
    >
      <Input
        autoComplete={autoComplete}
        placeholder={r(t('registerForm.email.placeholder'))}
        data-testid={`${testIdNamespace}:email`}
      />
    </Form.Item>
  );
};

export const ConfirmEmailFormItem = ({
  label = i18n.t('registration:registerForm.confirmEmail.label'),
  fieldName = 'confirmEmail',
  autoComplete = 'off',
  ...props
}: Props) => {
  const { t } = useTranslation('registration');
  return (
    <Form.Item
      label={label}
      name={fieldName}
      validateFirst
      validateTrigger={['onBlur', 'onChange']}
      dependencies={['email']}
      rules={[
        {
          validator: emailValidator,
          message: t('registerForm.email.pattern'),
          validateTrigger: 'onBlur',
        },
        {
          required: true,
          message: t('registerForm.confirmEmail.required'),
          validateTrigger: 'onBlur',
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue('email')?.trim() === value?.trim()) {
              return Promise.resolve();
            }
            return Promise.reject(t('registerForm.confirmEmail.confirm'));
          },
          validateTrigger: 'onBlur',
        }),
      ]}
      {...props}
    >
      <Input
        autoComplete={autoComplete}
        placeholder={r(t('registerForm.confirmEmail.placeholder'))}
        data-testid={`${testIdNamespace}:confirmEmail`}
      />
    </Form.Item>
  );
};
