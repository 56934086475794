import React, { useCallback, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { PhoneNumberUtil } from 'google-libphonenumber';
import ReactPhoneInput from 'react-phone-input-2';
import SizeContext from 'antd/lib/config-provider/SizeContext';
import { InputProps } from 'antd/lib/input';
import { FormItemProps } from 'antd/lib/form/FormItem';
import 'react-phone-input-2/lib/high-res.css';

import i18n from 'modules/localization';
import { usePrevious } from 'utils/hooks/usePrevious';

import './styles.css';

type Props = FormItemProps & {
  label?: string;
  fieldName?: string;
  country?: string;
  inputProps?: PhoneInputProps;
};

type PhoneInputProps = Omit<InputProps, 'onChange'> & {
  onChange?: (value: string) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  value?: string;
  country?: string;
};

const PhoneInput = ({ onChange = () => void 0, onBlur, value, country, ...props }: PhoneInputProps) => {
  const { t } = useTranslation('common');
  const innerRef = useRef<typeof ReactPhoneInput>(null);
  const prevValue = usePrevious<string | undefined>(value, undefined);
  const size = useContext(SizeContext);
  const isLarge = size === 'large';

  const onChangeRaw = useCallback(
    (text: string) => {
      // numberInputRef is not described in the types for react-phone-input-2
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const input = (innerRef.current as any).numberInputRef;
      const startIndex = input.selectionStart ?? 0;
      // Issue: https://github.com/bl00mber/react-phone-input-2/issues/467
      if (startIndex === 1 && (prevValue?.length || 0) > text.length) {
        const val = input.value;
        input.value = '';
        input.value = val;
      }

      // prevent form update when you try to delete non-numeric symbol
      if (text !== prevValue?.replace(/^\+/, '')) {
        onChange(Boolean(text) ? `+${text}` : '');
      }
    },
    [prevValue, onChange]
  );

  return (
    <ReactPhoneInput
      country={country}
      value={value}
      containerClass="customStyle"
      enableSearch
      searchPlaceholder={t('phoneField.placeholder')}
      searchNotFound={t('phoneField.notFound')}
      jumpCursorToEnd={false}
      // @ts-ignore
      ref={innerRef}
      onBlur={onBlur}
      onChange={onChangeRaw}
      inputClass={`${isLarge ? 'ant-input-lg' : ''} reactPhoneInput`}
      enableAreaCodes
      {...props}
    />
  );
};

const phoneUtil = PhoneNumberUtil.getInstance();
export const knownPhoneCodes = [
  93, 355, 213, 376, 244, 1268, 54, 374, 297, 61, 43, 994, 1242, 973, 880, 1246, 375, 32, 501, 229, 975, 591, 387, 267,
  55, 246, 673, 359, 226, 257, 855, 237, 1, 238, 599, 236, 235, 56, 86, 57, 269, 243, 242, 506, 225, 385, 53, 599, 357,
  420, 45, 253, 1767, 1, 593, 20, 503, 240, 291, 372, 251, 679, 358, 33, 594, 689, 241, 220, 995, 49, 233, 30, 1473,
  590, 1671, 502, 224, 245, 592, 509, 504, 852, 36, 354, 91, 62, 98, 964, 353, 972, 39, 1876, 81, 962, 7, 254, 686, 383,
  965, 996, 856, 371, 961, 266, 231, 218, 423, 370, 352, 853, 389, 261, 265, 60, 960, 223, 356, 692, 596, 222, 230, 52,
  691, 373, 377, 976, 382, 212, 258, 95, 264, 674, 977, 31, 687, 64, 505, 227, 234, 850, 47, 968, 92, 680, 970, 507,
  675, 595, 51, 63, 48, 351, 1, 974, 262, 40, 7, 250, 1869, 1758, 1784, 685, 378, 239, 966, 221, 381, 248, 232, 65, 421,
  386, 677, 252, 27, 82, 211, 34, 94, 249, 597, 268, 46, 41, 963, 886, 992, 255, 66, 670, 228, 676, 1868, 216, 90, 993,
  688, 256, 380, 971, 44, 1, 598, 998, 678, 39, 58, 84, 967, 260, 263,
];

const phoneValidator = (rule: unknown, value: string) => {
  try {
    if (value && value !== '+' && !knownPhoneCodes.includes(parseInt(value, 10))) {
      phoneUtil.isValidNumberForRegion(phoneUtil.parse(value));
    }

    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};

export const PhoneFormItem = ({
  label = i18n.t('common:phoneField.defaultLabel'),
  fieldName = 'phone',
  country = '',
  inputProps,
  ...props
}: Props) => (
  <Form.Item
    label={label}
    name={fieldName}
    validateTrigger={['onChange', 'onBlur']}
    rules={[{ validator: phoneValidator }]}
    {...props}
  >
    <PhoneInput country={country} {...inputProps} />
  </Form.Item>
);
