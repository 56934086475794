import { useState } from 'react';
import { Button, Checkbox, Col, Form, Row, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { RadioChangeEvent } from 'antd/lib/radio';

import { PasswordConfirmFormItem, PasswordFormItem } from 'components/common/forms/password';
import { AuthStyledForm } from 'components/common/forms/AuthStyleForm';
import { Link } from 'components/common/link';
import { PageRoute } from 'components/routing/utils';
import { ConfirmEmailFormItem, EmailFormItem } from 'components/registration/components/email';
import { useResponseErrors } from 'utils/hooks/useServerErrors';
import { RegisterRequest } from 'modules/api-requests/registration/entities';
import { FieldErrors } from 'modules/api-requests/entities';
import { SelectOption } from 'utils/getOptionsFromIdName';
import { r } from 'utils/r';
import i18n from 'modules/localization';

import { ProfileForm } from './components/ProfileForm';
import { CompanyProfileForm } from './components/CompanyProfileForm';
import { testIdNamespace } from './utils';

export type RegisterFormValues = Omit<RegisterRequest, 'referral' | 'source'> & {
  agreement: boolean;
  confirmEmail: string;
  confirmPassword: string;
};

export type RegisterFormProps = {
  handleSubmit: (data: RegisterFormValues) => void;
  initialValues: Partial<RegisterFormValues>;
  countriesOptions: SelectOption[];
  messengersOptions: SelectOption[];
  isLoading?: boolean;
  errorFields: FieldErrors;
};

export const RegisterForm = ({
  handleSubmit,
  initialValues,
  isLoading,
  messengersOptions,
  countriesOptions,
  errorFields,
}: RegisterFormProps) => {
  const { t } = useTranslation('registration');
  const [accountType, setAccountType] = useState('individual');
  const [form] = Form.useForm<RegisterFormValues>();
  const isAgreed = Form.useWatch('agreement', form);

  const onChangeAccountType = (e: RadioChangeEvent) => setAccountType(e.target.value);

  const { onFormValuesChange } = useResponseErrors({ form, errorFields });

  const emailAndPasswordFields = (
    <>
      <Row gutter={16}>
        <Col span={24} md={12}>
          <EmailFormItem label="" autoComplete="new-email" />
        </Col>
        <Col span={24} md={12}>
          <ConfirmEmailFormItem label="" autoComplete="new-confirm-email" />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24} md={12}>
          <PasswordFormItem
            autoComplete="new-password"
            label=""
            inputProps={{ placeholder: r(t('registerForm.passwordPlaceholder')) }}
            testId={`${testIdNamespace}:password`}
          />
        </Col>
        <Col span={24} md={12}>
          <PasswordConfirmFormItem
            autoComplete="new-confirm-password"
            label=""
            inputProps={{ placeholder: r(t('registerForm.confirmPasswordPlaceholder')) }}
            testId={`${testIdNamespace}:confirmPassword`}
          />
        </Col>
      </Row>
    </>
  );
  const countryCode = String(initialValues?.countryId).toLocaleLowerCase();

  return (
    <AuthStyledForm
      name="register_form"
      initialValues={initialValues}
      form={form}
      layout="horizontal"
      onFinish={handleSubmit}
      onValuesChange={onFormValuesChange}
      autoComplete="new-password"
      scrollToFirstError
      size="large"
    >
      <Row>
        <Col span={24}>
          <Form.Item label={t('registerForm.accountTypeField.label')}>
            <Radio.Group value={accountType} onChange={onChangeAccountType}>
              <Radio value="individual" data-testid={`${testIdNamespace}:accountType:individual`}>
                {i18n.t('registration:registerForm.accountTypeField.individual')}
              </Radio>
              <Radio value="company" data-testid={`${testIdNamespace}:accountType:company`}>
                {i18n.t('registration:registerForm.accountTypeField.company')}
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      {accountType === 'individual' ? (
        <>
          {emailAndPasswordFields}
          <ProfileForm
            countryCode={countryCode}
            countriesOptions={countriesOptions}
            messengersOptions={messengersOptions}
          />
        </>
      ) : (
        <CompanyProfileForm
          countryCode={countryCode}
          emailAndPasswordFields={emailAndPasswordFields}
          countriesOptions={countriesOptions}
          messengersOptions={messengersOptions}
        />
      )}

      <Form.Item>
        <Form.Item name="agreement" valuePropName="checked" noStyle>
          <Checkbox data-testid={`${testIdNamespace}:agreement`}>{t('registerForm.agreementCheckbox')}</Checkbox>
        </Form.Item>
        <Link target="_blank" to={PageRoute.terms}>
          {t('registerForm.termsOfUse')}
        </Link>
        {', '}
        <Link target="_blank" to={PageRoute.cookie}>
          {t('registerForm.cookiePolicy')}
        </Link>
        {', '}
        <Link target="_blank" to={PageRoute.privacy}>
          {t('registerForm.privacyPolicy')}
        </Link>
      </Form.Item>

      <Row justify="center">
        <Col xs={24} md={24}>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            disabled={!isAgreed}
            className="w-100"
            style={{ marginBottom: 35 }}
            data-testid={`${testIdNamespace}:submitButton`}
          >
            {t('registerForm.registerButton')}
          </Button>
        </Col>
      </Row>
    </AuthStyledForm>
  );
};
