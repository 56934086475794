import { useTranslation } from 'react-i18next';
import { Form, Button, Layout } from 'antd';
import { Helmet } from 'react-helmet-async';

import { Link } from 'components/common/link';
import { PasswordFormItem, PasswordConfirmFormItem } from 'components/common/forms/password';
import { FormContainer } from 'components/common/forms/form-container';
import { Footer } from 'components/layout/footer';
import { PageRoute } from 'components/routing/utils';
import { AuthStyledForm } from 'components/common/forms/AuthStyleForm';
import { BoldTitle } from 'components/common/BoldTitle';
import { MondiadLogo } from 'components/common/MondiadLogo';

export type NewPasswordFormValues = {
  password: string;
  confirmPassword: string;
};

type Props = {
  handleSubmit: (data: NewPasswordFormValues) => void;
  isLoading: boolean;
};

export const NewPasswordForm = ({ handleSubmit, isLoading }: Props) => {
  const { t } = useTranslation('newPassword');
  const [form] = Form.useForm();

  const onSubmit = (values: NewPasswordFormValues) => {
    handleSubmit(values);
  };

  return (
    <Layout>
      <Layout.Content>
        <FormContainer withMarginTop oneColumn>
          <MondiadLogo />
          <Helmet title="Mondiad" />
          <BoldTitle>{t('newPasswordForm.title')}</BoldTitle>
          <AuthStyledForm form={form} layout="vertical" onFinish={onSubmit} size="large">
            <PasswordFormItem
              label=""
              inputProps={{
                placeholder: t('newPasswordForm.password'),
              }}
            />
            <PasswordConfirmFormItem
              label=""
              inputProps={{
                placeholder: t('newPasswordForm.confirmPassword'),
              }}
            />

            <Button type="primary" htmlType="submit" loading={isLoading} className="w-100" style={{ fontWeight: 600 }}>
              {t('newPasswordForm.changeButton')}
            </Button>
            <Form.Item style={{ marginTop: 12 }}>
              <Link to={PageRoute.login}>
                <i className="fas fa-arrow-left" style={{ marginRight: 5, lineHeight: 1.3 }} />
                {t('newPasswordForm.loginButton')}
              </Link>
            </Form.Item>
          </AuthStyledForm>
        </FormContainer>
      </Layout.Content>
      <Footer />
    </Layout>
  );
};
