import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Layout, notification, Tabs } from 'antd';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { FormContainer } from 'components/common/forms/form-container';
import { Link } from 'components/common/link';
import { Footer } from 'components/layout/footer';
import { getGeoCountryRequest } from 'modules/api-requests/authorization';
import { registerRequest } from 'modules/api-requests/registration';
import { setCookie } from 'utils/cookie';
import { Country } from 'modules/api-requests/authorization/entities';
import { LanguageKeys } from 'modules/localization/entities';
import { Loader } from 'components/common/loader';
import { useCountriesOptions } from 'utils/hooks/useCountriesOptions';
import { useMessengersOptions } from 'utils/hooks/useMessangersOptions';
import { PageRoute } from 'components/routing/utils';
import { BoldTitle } from 'components/common/BoldTitle';
import { MondiadLogo } from 'components/common/MondiadLogo';

import { Error } from '../../modules/api-requests/entities';
import { useSourceFrom } from './hooks/useSourceFrom';
import { RoleTab, useRoleTab } from './hooks/useRoleTab';
import { RegisterForm, RegisterFormValues } from './RegisterForm';
import { pickRequestData } from './utils';

export const RegistrationPage = () => {
  const { t, i18n } = useTranslation('registration');
  const navigate = useNavigate();

  const { tab, handleTabChange } = useRoleTab();
  const isPublisher = tab === RoleTab.publisher;

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorFields, setErrorFields] = useState({});
  const [country, setCountry] = useState<Country>();
  const { executeRecaptcha } = useGoogleReCaptcha();

  // on init. Set source where the user came from
  useSourceFrom();

  useEffect(() => {
    setIsLoading(true);
    getGeoCountryRequest()
      .then(({ data: { data: country } }) => {
        setCountry(country);
      })
      .catch(() => undefined)
      .finally(() => setIsLoading(false));
  }, []);

  const { countriesOptions, isLoading: areCountriesLoading } = useCountriesOptions();
  const { messengersOptions, isLoading: areMessengersLoading } = useMessengersOptions();

  const initialValues = useMemo(() => {
    return { countryId: country?.id } as Partial<RegisterFormValues>;
  }, [country]);

  const handleSubmit = async (formValues: RegisterFormValues) => {
    if (!executeRecaptcha) return;
    setIsSubmitting(true);
    const captchaCode = await executeRecaptcha();
    const data = pickRequestData(formValues);
    const language = i18n.language as LanguageKeys;

    try {
      await registerRequest({ ...data, captchaCode, language }, isPublisher);

      setCookie('registerEmail', data.email);
      setIsSubmitting(false);
      navigate(PageRoute.registrationConfirmation);

      notification.success({
        message: t('successNotification.message'),
        description: t('successNotification.description'),
      });
    } catch (err) {
      setIsSubmitting(false);
      const error = err as AxiosError<Error>;
      if (!error.response) {
        throw err;
      }

      const errorFields = error.response.data.fieldErrors || {};

      setErrorFields(errorFields);
    }
  };

  if (isLoading || areCountriesLoading || areMessengersLoading || !executeRecaptcha) {
    return <Loader />;
  }

  const items = [
    {
      label: t('tabs.advertiser'),
      key: RoleTab.advertiser,
      children: <BoldTitle>{t('tabs.advertiserTitle')}</BoldTitle>,
    },
    {
      label: t('tabs.publisher'),
      key: RoleTab.publisher,
      children: <BoldTitle>{t('tabs.publisherTitle')}</BoldTitle>,
    },
  ];

  return (
    <Layout>
      <Layout.Content>
        <FormContainer withMarginTop>
          <MondiadLogo />
          <Helmet title="Mondiad" />
          <Tabs onChange={handleTabChange} activeKey={tab} items={items} />
          <RegisterForm
            initialValues={initialValues}
            handleSubmit={handleSubmit}
            messengersOptions={messengersOptions}
            countriesOptions={countriesOptions}
            isLoading={isSubmitting}
            errorFields={errorFields}
          />
        </FormContainer>

        <div style={{ textAlign: 'center' }}>
          {t('haveAccount')} <Link to={PageRoute.login}>{t('signIn')}</Link>
        </div>
      </Layout.Content>

      <Footer />
    </Layout>
  );
};
