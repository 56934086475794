import { useEffect, useMemo } from 'react';
import { FormInstance } from 'antd/lib/form';

import { clearErrors, clearErrorsOnFieldChange, setErrors } from 'utils/form';
import { FieldErrors } from 'modules/api-requests/entities';

const isEmpty = (errorFields?: FieldErrors) => {
  if (!errorFields) return true;
  return Object.keys(errorFields).length === 0;
};

type UseResponseErrorsProps = {
  form: FormInstance;
  errorFields: FieldErrors;
};

export const useResponseErrors = <FormValuesType>({ form, errorFields }: UseResponseErrorsProps) => {
  useEffect(() => {
    if (!isEmpty(errorFields)) {
      setErrors(form, errorFields);
    }
    return () => {
      clearErrors(form, errorFields);
    };
  }, [errorFields, form]);

  const onFormValuesChange = useMemo(() => clearErrorsOnFieldChange<FormValuesType>(form), [form]);

  return { onFormValuesChange };
};
