import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { useTranslation } from 'react-i18next';

import { SelectOption } from 'utils/getOptionsFromIdName';
import { testIdNamespace } from '../utils';

type Props = SelectProps<string> & {
  options: SelectOption[];
};

const { Option } = Select;

export const CountriesSelect = ({ options, ...rest }: Props) => {
  const { t } = useTranslation('registration');

  return (
    <Select
      placeholder={t('registerForm.countrySelect.placeholder')}
      showSearch
      filterOption
      optionFilterProp="children"
      data-testid={`${testIdNamespace}:country`}
      {...rest}
    >
      {options.map(({ value, label }) => (
        <Option value={value} key={value} data-testid={`${testIdNamespace}:country:${value}`}>
          {label}
        </Option>
      ))}
    </Select>
  );
};
