import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { testIdNamespace } from '../utils';

const maxLength = 250;

export const SourceInfoAboutUs = () => {
  const { t } = useTranslation('registration');

  return (
    <Form.Item
      name="sourceInfoAboutUs"
      rules={[{ max: maxLength, message: t('registerForm.sourceInfoAboutUs.max', { length: maxLength }) }]}
    >
      <Input
        placeholder={t('registerForm.sourceInfoAboutUs.placeholder')}
        data-testid={`${testIdNamespace}:sourceInfoAboutUs`}
      />
    </Form.Item>
  );
};
