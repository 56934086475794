import { PHONE_CODES } from 'components/const';
import { RegisterRequest } from 'modules/api-requests/registration/entities';
import { getCookie } from 'utils/cookie';
import { getSource } from './hooks/useSourceFrom';

import { RegisterFormValues } from './RegisterForm';

export const isValidPhone = (phoneValue?: string) =>
  !(phoneValue && (phoneValue === '+' || PHONE_CODES.includes(parseInt(phoneValue.replace('+', ''), 10))));

const getIntroducedBy = (): number | undefined => {
  const by = Math.abs(Number(getCookie('by')));
  return Number.isInteger(by) ? by : undefined;
};

export const pickRequestData = ({
  countryId,
  email,
  firstName,
  lastName,
  messengerId,
  messengerTypeId,
  password,
  phone,
  singUpForNewsletter,
  companyName,
  addressFirstLine,
  addressSecondLine,
  sourceInfoAboutUs,
}: RegisterFormValues): Omit<RegisterRequest, 'captchaCode'> => ({
  countryId,
  email: email.trim(),
  firstName: firstName.trim(),
  lastName: lastName.trim(),
  messengerId: messengerId?.trim() || undefined,
  messengerTypeId,
  password,
  singUpForNewsletter,
  referral: getCookie('referral') || '',
  httpReferrer: getCookie('referrer'),
  introducedBy: getIntroducedBy(),
  source: getSource() || undefined,
  phone: isValidPhone(phone) ? phone : undefined,
  companyName: companyName?.trim(),
  addressFirstLine: addressFirstLine?.trim(),
  addressSecondLine: addressSecondLine?.trim(),
  sourceInfoAboutUs: sourceInfoAboutUs?.trim(),
});

export const messengerIdMaxLength = 255;

export const testIdNamespace = 'RegisterForm';
